<template>
    <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur" spinner-variant="primary">
        <b-card v-if="isActive" no-body header-bg-variant="bluenavy" header="Richieste" header-text-variant="white" header-tag="h2" border-variant="bluenavy">
            <multiselect v-if="$store.getters.currentUser.usertype !== 'Sodalizio'" style="width: 100%;"
                         v-model="sportingclub"
                         :options="sportingclubs"
                         :multiple="false"
                         :close-on-select="true"
                         :clear-on-select="false"
                         placeholder="Seleziona un sodalizio"
                         label="displayname"
                         track-by="id"
                         :allow-empty="true"
                         selectLabel=""
                         selectedLabel=""
                         deselectLabel=""
                         showLabels=""
            >
                <template v-slot:noOptions>
                    Nessuna opzione disponibile
                </template>
            </multiselect>

            <b-tabs card justified v-model="tabIndex">
                <b-tab class="border border-primary border-top-0 rounded-bottom" title="Nuova richiesta" :title-link-class="linkClass(0)" active>
                    <div class="table-responsive">
                        <div class="mb-3 d-flex justify-content-between">
                            <button class="btn btn-info font-weight-bolder" @click.prevent="salvaInBozza"><i class="fas fa-save"></i> Salva in bozza</button>
                            <button class="btn btn-success font-weight-bolder" @click.prevent="saveCart"><i class="fas fa-cart-plus"></i> Aggiungi al carrello</button>
                        </div>

                        <table class="table table-hover table-head-custom table-vertical-center table-head-bg table-bordered border border-primary">
                            <thead>
                                <tr class="text-left">
                                    <th class="bg-primary"><span class="text-white text-hover-white">PRODOTTO</span></th>
                                    <th class="bg-primary text-right"><span class="text-white text-hover-white">PREZZO UNITARIO</span></th>
                                    <th class="bg-primary text-right"><span class="text-white text-hover-white">QUANTITÀ</span></th>
                                    <th class="bg-primary text-right"><span class="text-white text-hover-white">IMPORTO</span></th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="product in products">
                                    <td>{{product.label}}</td>
                                    <td class="text-right">{{ formatEuro(product.price) }}</td>
                                    <td class="text-right"><input class="text-right border border-primary" type="number" v-model="product.quantity" @change="changeQuantity(product)"></td>
                                    <th class="text-right">{{ formatEuro(product.subtotal) }}</th>
                                </tr>
                            </tbody>

                            <tfoot>
                                <tr>
                                    <th colspan="3" class="text-right bg-primary text-white">TOTALE</th>
                                    <th class="text-right text-primary font-size-h3">{{ formatEuro(totale) }}</th>
                                </tr>
                            </tfoot>
                        </table>

                        <div class="mt-3 d-flex justify-content-between">
                            <button class="btn btn-info font-weight-bolder" @click.prevent="salvaInBozza"><i class="fas fa-save"></i> Salva in bozza</button>
                            <button class="btn btn-success font-weight-bolder" @click.prevent="saveCart"><i class="fas fa-cart-plus"></i> Aggiungi al carrello</button>
                        </div>
                    </div>
                </b-tab>

                <b-tab class="border border-primary border-top-0 rounded-bottom" title="Richieste in Bozza" :title-link-class="linkClass(1)">
                    <SmartTable ref="richiesteBozza" url="/requestes/index" base_url="/requestes" base_path="/requestes" :fixed_filters="[{ name: 'idsportingclub', filter: (((typeof sportingclub !== 'undefined') && sportingclub && (typeof sportingclub.id !== 'undefined')) ? sportingclub.id : '') }, { name: 'idstate', filter: 'Bozza' }, { name: 'pagetype', filter: ($store.getters.currentUser.usertype === 'Sodalizio' ? 'sodalizio' : '') } ]" :has_checkboxes="false" :is_dialog="true" :has_details="true" custom_class="mx-0 px-0">
                        <template v-slot:title>
                            <h2 class="card-title"><span class="card-label font-weight-bolder text-dark font-size-h3">Richieste in Bozza</span></h2>
                        </template>

                        <template v-slot:item-actions="slotProps">
                            <td class="text-nowrap pr-0 text-right">
                                <button class="btn btn-sm btn-danger mr-1 font-weight-bolder" @click.prevent="deleteRequest(slotProps.values.item)"><i class="fas fa-trash-alt"></i> Elimina</button>
                                <button class="btn btn-sm btn-info mr-1 font-weight-bolder" @click.prevent="addToCart(slotProps.values.item)"><i class="fas fa-cart-plus"></i> Aggiungi al carrello</button>
                                <button class="btn btn-sm btn-success font-weight-bolder" @click="toggleRequest(slotProps.values.item)"><i class="fas fa-eye"></i> Dettagli</button>
                            </td>
                        </template>

                        <template v-slot:item-details="slotProps">
                            <table class="table table-hover table-head-custom table-vertical-center table-head-bg table-bordered border border-primary">
                                <thead>
                                    <tr class="text-left">
                                        <th class="bg-primary"><span class="text-white text-hover-white">PRODOTTO</span></th>
                                        <th class="bg-primary text-right"><span class="text-white text-hover-white">PREZZO UNITARIO</span></th>
                                        <th class="bg-primary text-right"><span class="text-white text-hover-white">QUANTITÀ</span></th>
                                        <th class="bg-primary text-right"><span class="text-white text-hover-white">IMPORTO</span></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="product in slotProps.values.item.products">
                                        <td>{{product.label}}</td>
                                        <td class="text-right">{{ formatEuro(product.price) }}</td>
                                        <td class="text-right">{{ product.quantity }}</td>
                                        <th class="text-right">{{ formatEuro(product.subtotal) }}</th>
                                    </tr>
                                </tbody>

                                <tfoot>
                                    <tr>
                                        <th colspan="3" class="text-right bg-primary text-white">TOTALE</th>
                                        <th class="text-right text-primary font-size-h3">{{ slotProps.values.item.total }}</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </template>
                    </SmartTable>
                </b-tab>

                <b-tab class="border border-primary border-top-0 rounded-bottom" title="Gestione Richieste" :title-link-class="linkClass(2)">
                    <b-card-text v-if="$store.getters.currentUser.usertype === 'Comitato'">
                        <div class="row">
                            <div class="col-md-12 d-flex justify-content-center align-items-start align-content-center">
                                <CommitteeChargeCredit :idcommittee="$store.getters.currentUser.admincommittee" v-on:credit-recharged="CreditRecharged"></CommitteeChargeCredit>
                            </div>
                        </div>
                    </b-card-text>

                    <SmartTable ref="richiesteGestione" url="/requestes/index" base_url="/requestes" base_path="/requestes" :fixed_filters="[{ name: 'idsportingclub', filter: (((typeof sportingclub !== 'undefined') && sportingclub && (typeof sportingclub.id !== 'undefined')) ? sportingclub.id : '') }, { name: 'idstate', filter: 'Gestione' }, { name: 'pagetype', filter: ($store.getters.currentUser.usertype === 'Sodalizio' ? 'sodalizio' : '') } ]" :has_checkboxes="false" :is_dialog="true" :has_details="true" custom_class="mx-0 px-0">
                        <template v-slot:title>
                            <h2 class="card-title"><span class="card-label font-weight-bolder text-dark font-size-h3">Gestione Richieste</span></h2>
                        </template>

                        <template v-slot:td-idstate="slotProps">
                            <td v-if="slotProps.values.field.is_visible_for_index">
                                <div class="text-white d-flex flex-column justify-content-center align-items-start align-content-center flex-nowrap">
                                    <span v-if="slotProps.values.item.idstate === 'Rifiutata'" class="badge pill bg-danger mb-1" style="font-size: 0.9em;">RIFIUTATA</span>

                                    <span v-if="slotProps.values.item.idstate === 'Accettata'" class="badge pill bg-success mb-1" style="font-size: 0.9em;">ACCETTATA</span>

                                    <span v-if="slotProps.values.item.idstate === 'Integrazione'" class="badge pill bg-warning mb-1" style="font-size: 0.9em;">INTEGRAZIONE</span>

                                    <span v-if="slotProps.values.item.idstate === 'Bozza'" class="badge pill bg-info mb-1" style="font-size: 0.9em;">BOZZA</span>

                                    <span v-if="slotProps.values.item.idstate === 'Pagamento'" class="badge pill bg-primary mb-1" style="font-size: 0.9em;">PAGAMENTO</span>

                                    <span v-if="slotProps.values.item.idstate === 'Carrello'" class="badge pill bg-primary mb-1" style="font-size: 0.9em;">CARRELLO</span>

                                    <span v-if="slotProps.values.item.info" v-b-popover.hover.top="slotProps.values.item.info" title="Informazioni Comitato" class="btn btn-icon btn-circle btn-sm btn-primary"><i class="fas fa-info-circle"></i></span>

                                    <a v-if="slotProps.values.item.receipt" :href="appendToken(slotProps.values.item.receipt.href)" target="_blank" class="badge pill bg-primary mb-1 text-white text-hover-white" style="font-size: 0.9em;"><i class="fas fa-receipt text-white text-hover-white"></i> RICEVUTA</a>
                                </div>
                            </td>
                        </template>

                        <template v-slot:item-actions="slotProps">
                            <td class="text-nowrap pr-0 text-right">
                                <button v-if="(slotProps.values.item.idstate === 'Pagamento' || slotProps.values.item.idstate === 'Integrazione') && ($store.getters.currentUser.usertype !== 'Sodalizio')" class="btn btn-sm btn-warning mr-1 font-weight-bolder" @click.prevent="additionsRequest(slotProps.values.item)"><i class="far fa-comments"></i> Integrazioni</button>

                                <b-modal ref="integrazioniModal" size="lg" hide-footer title="Integrazioni Richiesta">
                                    <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur" spinner-variant="primary">
                                        <form>
                                            <div class="form-group">
                                                <label for="info_integrazioni">Informazioni:</label>
                                                <textarea v-model="integrazioni" rows="5" class="form-control">
                                                </textarea>
                                            </div>
                                        </form>

                                        <div class="d-flex justify-content-end">
                                            <b-button class="mt-3" variant="danger" @click.prevent="hideIntegrazioniModal">Annulla</b-button>
                                            <b-button class="ml-3 mt-3" variant="success" @click.prevent="confermaIntegrazioniModal">Conferma</b-button>
                                        </div>
                                    </b-overlay>
                                </b-modal>

                                <button v-if="(slotProps.values.item.idstate === 'Pagamento' || slotProps.values.item.idstate === 'Integrazione') && ($store.getters.currentUser.usertype !== 'Sodalizio') && (slotProps.values.item.credit >= slotProps.values.item.totalnumber)" class="btn btn-sm btn-success mr-1 font-weight-bolder" @click.prevent="acceptRequest(slotProps.values.item)"><i class="fas fa-vote-yea"></i> Accetta</button>

                                <button v-if="(slotProps.values.item.idstate === 'Pagamento' || slotProps.values.item.idstate === 'Integrazione') && ($store.getters.currentUser.usertype !== 'Sodalizio') && (slotProps.values.item.credit <= slotProps.values.item.totalnumber)" class="btn btn-sm btn-secondary mr-1 font-weight-bolder"><i class="fas fa-vote-yea"></i> Credito insufficiente</button>

                                <b-modal ref="accettaModal" size="lg" hide-footer title="Accetta Richiesta">
                                    <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur" spinner-variant="primary">
                                        <form>
                                            <div class="form-group">
                                                <label for="info_accetta">Informazioni:</label>
                                                <textarea v-model="integrazioni" rows="5" class="form-control">
                                                </textarea>
                                            </div>
                                        </form>

                                        <div class="form-group">
                                            <label>Carica Ricevuta</label>
                                            <FileUpload id="filereceipt" name="filereceipt" :multiple="false" v-model="filereceipt"></FileUpload>

                                            <div v-for="(error, index) in fileErrors.filereceipt" class="invalid-feedback d-block">{{error}}</div>
                                        </div>

                                        <div class="d-flex justify-content-end">
                                            <b-button class="mt-3" variant="danger" @click.prevent="hideAccettaModal">Annulla</b-button>
                                            <b-button class="ml-3 mt-3" variant="success" @click.prevent="confermaAccettaModal">Conferma</b-button>
                                        </div>
                                    </b-overlay>
                                </b-modal>

                                <button v-if="(slotProps.values.item.idstate === 'Pagamento' || slotProps.values.item.idstate === 'Integrazione') && ($store.getters.currentUser.usertype !== 'Sodalizio')" class="btn btn-sm btn-danger mr-1 font-weight-bolder" @click.prevent="denyRequest(slotProps.values.item)"><i class="fas fa-eject"></i> Rifiuta</button>
                                <button class="btn btn-sm btn-primary font-weight-bolder" @click="toggleRequest(slotProps.values.item)"><i class="fas fa-eye"></i> Dettagli</button>
                            </td>
                        </template>

                        <template v-slot:item-details="slotProps">
                            <table class="table table-hover table-head-custom table-vertical-center table-head-bg table-bordered border border-primary">
                                <thead>
                                    <tr class="text-left">
                                        <th class="bg-primary"><span class="text-white text-hover-white">PRODOTTO</span></th>
                                        <th class="bg-primary text-right"><span class="text-white text-hover-white">PREZZO UNITARIO</span></th>
                                        <th class="bg-primary text-right"><span class="text-white text-hover-white">QUANTITÀ</span></th>
                                        <th class="bg-primary text-right"><span class="text-white text-hover-white">IMPORTO</span></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="product in slotProps.values.item.products">
                                        <td>{{product.label}}</td>
                                        <td class="text-right">{{ formatEuro(product.price) }}</td>
                                        <td class="text-right">{{ product.quantity }}</td>
                                        <th class="text-right">{{ formatEuro(product.subtotal) }}</th>
                                    </tr>
                                    <tr>
                                        <th colspan="3" class="text-right bg-primary text-white">TOTALE</th>
                                        <th class="text-right text-primary font-size-h3">{{ slotProps.values.item.total }}</th>
                                    </tr>
                                    <tr v-if="slotProps.values.item.info">
                                        <th colspan="4" class="text-left bg-primary text-white">INFORMAZIONI COMITATO</th>
                                    </tr>
                                    <tr v-if="slotProps.values.item.info">
                                        <td colspan="4"><pre>{{slotProps.values.item.info}}</pre></td>
                                    </tr>
                                </tbody>
                            </table>
                        </template>
                    </SmartTable>
                </b-tab>
            </b-tabs>
        </b-card>

        <div v-if="!isActive" class="p-10">
            <h3>Richieste non attive</h3>
            <p>Questa funzionalità al momento non è attiva. Richiedi di abilitarla al tuo comitato.</p>
        </div>
    </b-overlay>
</template>

<script>
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
 import ApiService from "@/core/services/api.service";
 import JwtService from "@/core/services/jwt.service";
 import SmartTable from "@/view/components/SmartTable.vue";
 import FileUpload from "@/view/components/FileUpload.vue";
 import CommitteeChargeCredit from "@/view/components/CommitteeChargeCredit.vue";
 import Swal from 'sweetalert2';

 export default {
     data() {
         return {
             isActive: false,

             loading: false,

             request_id: null,
             currentRequest: null,

             sportingclub: null,

             sportingclubs: [],

             tabIndex: 0,

             products: [],

             requests: [],

             integrazioni: '',

             filereceipt: '',
             fileErrors: [],
         };
     },

     components: {
         SmartTable,
         FileUpload,
         CommitteeChargeCredit,
     },

     created() {
         this.loading = true;

         ApiService.query('/requestes/is-active')
                   .then((response) => {
                       this.isActive = response.data.isactive;

                       this.loading = false;
                   })
                   .catch((error) => {
                       console.log(error);

                       this.loading = false;
                   })

         if (this.$store.getters.currentUser.usertype !== 'Sodalizio') {
             this.loading = true;

             let url = '';

             url = '/requestes/load-sportingclubs?idsportingclub=all';
             ApiService.query(url)
                       .then((response) => {
                           this.sportingclubs = response.data.sportingclubs;

                           let idsportingclub = localStorage.getItem('currentSportingClubId');

                           if (this.sportingclubs.length === 1) {
                               this.sportingclub = JSON.parse(JSON.stringify(this.sportingclubs[0]));
                           }
                           else if (idsportingclub) {
                               let sportingclub = this.sportingclubs.find(function(item) {
                                   return parseInt(item.id) === parseInt(idsportingclub);
                               });

                               if (sportingclub) {
                                   this.sportingclub = JSON.parse(JSON.stringify(sportingclub));
                               }
                           }

                           this.loading = false;
                       })
                       .catch((error) => {
                           console.log(error);

                           this.loading = false;
                       })
         }
         else {
             this.sportingclubs = [];

             this.sportingclub = {
                 id: this.$store.getters.currentUser.adminsportingclub[0],
             };
         }
     },

     mounted() {
         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Richieste Sodalizio", route: "/requestes" },
         ]);
     },

     computed: {
         totale() {
             let totale = 0;

             for (let i in this.products) {
                 totale += this.products[i].subtotal;
             }

             return totale;
         },

         draftrequestslength() {
             let c = 0;
             for (let i in this.requests) {
                 if (this.requests[i].state === 'Bozza') c++;
             }
             return c;
         },

         cartrequestslength() {
             let c = 0;
             for (let i in this.requests) {
                 if (this.requests[i].state === 'Carrello') c++;
             }
             return c;
         },

         sentrequestslength() {
             let c = 0;
             for (let i in this.requests) {
                 if (this.requests[i].state !== 'Bozza') c++;
             }
             return c;
         },
     },

     watch: {
         products: {
             handler(items) {
                 for (let i in items) {
                     items[i].subtotal = items[i].price * items[i].quantity;
                 }
             },
             deep: true,
         },

         sportingclub: {
             handler(sportingclub) {
                 if (sportingclub && sportingclub.id) {
                     this.loading = true;

                     localStorage.setItem('currentSportingClubId', sportingclub.id);

                     let url = '';

                     url = '/requestes/load-products?idsportingclub=' + sportingclub.id;
                     ApiService.query(url)
                               .then((response) => {
                                   this.products = response.data.products;

                                   this.loading = false;
                               })
                               .catch((error) => {
                                   console.log(error);

                                   this.loading = false;
                               })

                     if (typeof this.$refs.richiesteBozza !== "undefined") this.$refs.richiesteBozza.refresh();
                     if (typeof this.$refs.richiesteGestione !== "undefined") this.$refs.richiesteGestione.refresh();

                     this.request_id = null;
                 }
                 else
                 {
                     this.loading = true;

                     this.products = [];

                     localStorage.removeItem('currentSportingClubId');

                     this.$refs.richiesteBozza.refresh();
                     this.$refs.richiesteGestione.refresh();

                     this.loading = false;
                 }
             },
             deep: true,
         },
     },

     methods: {
         linkClass(idx) {
             if (this.tabIndex === idx) {
                 return ['text-primary', 'border-top-primary', 'border-left-primary', 'border-right-primary', 'text-center', 'font-size-h5']
             } else {
                 return ['bg-primary', 'text-white', 'font-size-h5']
             }
         },

         closeModal() {
         },

         formatEuro(number) {
             return (new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(number));
         },

         changeQuantity(product) {
             if (product.quantity < 0) product.quantity = 0;
         },

         toggleRequest(request) {
             let show = false;
             if (typeof request.show === 'undefined') show = false;
             else if (request.show) show = true;

             this.$set(request, 'show', !show);
         },

         salvaInBozza() {
             if (typeof this.sportingclub !== "undefined" && this.sportingclub && this.sportingclub.id !== "undefined") {
                 this.loading = true;

                 let url = '';

                 url = '/requestes/save-draft?idsportingclub=' + this.sportingclub.id;

                 ApiService.post(url, { request_id: this.request_id, products: this.products })
                           .then((response) => {
                               if (response.data.status === 'OK') {
                                   this.$bvToast.toast('Richiesta salvata correttamente in bozza.', {
                                       title: 'Dati salvati correttamente',
                                       variant: 'success',
                                       solid: true
                                   });

                                   this.request_id = response.data.request_id;

                                   this.loading = false;

                                   if (typeof this.$refs.richiesteBozza !== "undefined") this.$refs.richiesteBozza.refresh();
                                   if (typeof this.$refs.richiesteGestione !== "undefined") this.$refs.richiesteGestione.refresh();
                               }

                               if (response.data.status === 'KO') {
                                   this.$bvToast.toast('Si è verificato un errore nel salvataggio della richiesta.', {
                                       title: 'Errore nel salvataggio della richiesta',
                                       variant: 'danger',
                                       solid: true
                                   });

                                   this.loading = false;
                               }
                           })
                           .catch((error) => {
                               this.$bvToast.toast('Si è verificato un errore nel salvataggio della richiesta.', {
                                   title: 'Errore nel salvataggio della richiesta',
                                   variant: 'danger',
                                   solid: true
                               });

                               this.loading = false;
                           })
             }
         },

         saveCart() {
             if (typeof this.sportingclub !== "undefined" && this.sportingclub && this.sportingclub.id !== "undefined") {
                 this.loading = true;

                 let url = '';

                 url = '/requestes/save-cart?idsportingclub=' + this.sportingclub.id;

                 ApiService.post(url, { request_id: this.request_id, products: this.products })
                           .then((response) => {
                               if (response.data.status === 'OK') {
                                   this.$bvToast.toast('Richiesta salvata correttamente nel Carrello.', {
                                       title: 'Dati salvati correttamente',
                                       variant: 'success',
                                       solid: true
                                   });

                                   this.request_id = null;

                                   for(let i in this.products) {
                                       this.products[i].quantity = 0;
                                   }

                                   this.loading = false;

                                   if (typeof this.$refs.richiesteBozza !== "undefined") this.$refs.richiesteBozza.refresh();
                                   if (typeof this.$refs.richiesteGestione !== "undefined") this.$refs.richiesteGestione.refresh();
                               }

                               if (response.data.status === 'KO') {
                                   this.$bvToast.toast('Si è verificato un errore nel salvataggio della richiesta.', {
                                       title: 'Errore nel salvataggio della richiesta',
                                       variant: 'danger',
                                       solid: true
                                   });

                                   this.loading = false;
                               }
                           })
                           .catch((error) => {
                               this.$bvToast.toast('Si è verificato un errore nel salvataggio della richiesta.', {
                                   title: 'Errore nel salvataggio della richiesta',
                                   variant: 'danger',
                                   solid: true
                               });

                               this.loading = false;
                           })
             }
         },

         loadRequestes() {
             if (typeof this.sportingclub !== "undefined" && typeof this.sportingclub.id !== "undefined") {
                 this.loading = true;

                 let url = '';

                 url = '/requestes/load-requestes?idsportingclub=' + this.sportingclub.id;
                 ApiService.query(url)
                           .then((response) => {
                               this.requests = response.data.requests;

                               this.loading = false;
                           })
                           .catch((error) => {
                               console.log(error);

                               this.loading = false;
                           })
             }
         },

         deleteRequest(request) {
             if ((typeof request.sportingclubid !== "undefined") || (typeof this.sportingclub !== "undefined" && this.sportingclub && typeof this.sportingclub.id !== "undefined")) {
                 Swal.fire({
                     title: 'Conferma eliminazione',
                     text: "Sei sicuro di voler confermare l\'eliminazione della richiesta?",
                     icon: 'error',
                     showCancelButton: true,
                     showConfirmButton: true,
                     confirmButtonText: 'Conferma',
                     cancelButtonText: 'Annulla',
                 }).then((result) => {
                     if (result.isConfirmed) {
                         this.loading = true;

                         let url = '';

                         let sportingclubid = null;
                         if (typeof request.sportingclubid !== "undefined") sportingclubid = request.sportingclubid;
                         if (typeof this.sportingclub !== "undefined" && this.sportingclub && typeof this.sportingclub.id !== "undefined") sportingclubid = this.sportingclub.id;

                         url = '/requestes/delete-request?idsportingclub=' + sportingclubid + '&request_id=' + request.id;
                         ApiService.query(url)
                                   .then((response) => {
                                       if (response.data.status === 'OK') {
                                           this.$bvToast.toast('Richiesta eliminata correttamente.', {
                                               title: 'Richiesta eliminata correttamente',
                                               variant: 'success',
                                               solid: true
                                           });

                                           this.loading = false;

                                           if (typeof this.$refs.richiesteBozza !== "undefined") this.$refs.richiesteBozza.refresh();
                                           if (typeof this.$refs.richiesteGestione !== "undefined") this.$refs.richiesteGestione.refresh();
                                       }

                                       if (response.data.status === 'KO') {
                                           this.$bvToast.toast('Si è verificato un errore nel\'eliminazione della richiesta.', {
                                               title: 'Errore nel\'eliminazione della richiesta',
                                               variant: 'danger',
                                               solid: true
                                           });

                                           this.loading = false;
                                       }
                                   })
                                   .catch((error) => {
                                       this.$bvToast.toast('Si è verificato un errore nel\'eliminazione della richiesta.', {
                                           title: 'Errore nel\'eliminazione della richiesta',
                                           variant: 'danger',
                                           solid: true
                                       });

                                       this.loading = false;
                                   })
                     }
                 })
             }
         },

         addToCart(request) {
             if ((typeof request.sportingclubid !== "undefined") || (typeof this.sportingclub !== "undefined" && this.sportingclub && typeof this.sportingclub.id !== "undefined")) {
                 Swal.fire({
                     title: 'Conferma aggiunta al Carrello',
                     text: "Sei sicuro di voler aggiungere al Carrello la richiesta?",
                     icon: 'success',
                     showCancelButton: true,
                     showConfirmButton: true,
                     confirmButtonText: 'Conferma',
                     cancelButtonText: 'Annulla',
                 }).then((result) => {
                     if (result.isConfirmed) {
                         this.loading = true;

                         let url = '';

                         let sportingclubid = null;
                         if (typeof request.sportingclubid !== "undefined") sportingclubid = request.sportingclubid;
                         if (typeof this.sportingclub !== "undefined" && this.sportingclub && typeof this.sportingclub.id !== "undefined") sportingclubid = this.sportingclub.id;

                         url = '/requestes/add-request-to-cart?idsportingclub=' + sportingclubid + '&request_id=' + request.id;
                         ApiService.query(url)
                                   .then((response) => {
                                       if (response.data.status === 'OK') {
                                           this.$bvToast.toast('Richiesta aggiunta correttamente al carrello.', {
                                               title: 'Richiesta aggiunta correttamente al carrello',
                                               variant: 'success',
                                               solid: true
                                           });

                                           this.loading = false;

                                           if (typeof this.$refs.richiesteBozza !== "undefined") this.$refs.richiesteBozza.refresh();
                                           if (typeof this.$refs.richiesteGestione !== "undefined") this.$refs.richiesteGestione.refresh();
                                       }

                                       if (response.data.status === 'KO') {
                                           this.$bvToast.toast('Si è verificato un errore nell\'aggiunta della richiesta al carrello.', {
                                               title: 'Errore nell\'aggiunta della richiesta al carrello',
                                               variant: 'danger',
                                               solid: true
                                           });

                                           this.loading = false;
                                       }
                                   })
                                   .catch((error) => {
                                       this.$bvToast.toast('Si è verificato un errore nell\'aggiunta della richiesta al carrello.', {
                                           title: 'Errore nell\'aggiunta della richiesta al carrello',
                                           variant: 'danger',
                                           solid: true
                                       });

                                       this.loading = false;
                                   })
                     }
                 })
             }
         },

         denyRequest(request) {
             if ((typeof request.sportingclubid !== "undefined") || (typeof this.sportingclub !== "undefined" && this.sportingclub && typeof this.sportingclub.id !== "undefined")) {
                 Swal.fire({
                     title: 'Conferma di Rifiutare la Richiesta',
                     text: "Sei sicuro di voler Rifiutare la Richiesta?",
                     icon: 'error',
                     showCancelButton: true,
                     showConfirmButton: true,
                     confirmButtonText: 'Conferma',
                     cancelButtonText: 'Annulla',
                 }).then((result) => {
                     if (result.isConfirmed) {
                         this.loading = true;

                         let url = '';

                         let sportingclubid = null;
                         if (typeof request.sportingclubid !== "undefined") sportingclubid = request.sportingclubid;
                         if (typeof this.sportingclub !== "undefined" && this.sportingclub && typeof this.sportingclub.id !== "undefined") sportingclubid = this.sportingclub.id;

                         url = '/requestes/deny-request?idsportingclub=' + sportingclubid + '&request_id=' + request.id;
                         ApiService.query(url)
                                   .then((response) => {
                                       if (response.data.status === 'OK') {
                                           this.$bvToast.toast('Richiesta correttamente rifiutata.', {
                                               title: 'Richiesta correttamente rifiutata',
                                               variant: 'success',
                                               solid: true
                                           });

                                           this.loading = false;

                                           if (typeof this.$refs.richiesteBozza !== "undefined") this.$refs.richiesteBozza.refresh();
                                           if (typeof this.$refs.richiesteGestione !== "undefined") this.$refs.richiesteGestione.refresh();
                                       }

                                       if (response.data.status === 'KO') {
                                           this.$bvToast.toast('Si è verificato un errore nel rifiutare la Richiesta.', {
                                               title: 'Errore nel riufiutare la Richiesta.',
                                               variant: 'danger',
                                               solid: true
                                           });

                                           this.loading = false;
                                       }
                                   })
                                   .catch((error) => {
                                       this.$bvToast.toast('Si è verificato un errore nel rifiutare la Richiesta.', {
                                           title: 'Errore nel riufiutare la Richiesta.',
                                           variant: 'danger',
                                           solid: true
                                       });

                                       this.loading = false;
                                   })
                     }
                 })
             }
         },

         additionsRequest(request) {
             this.request_id = request.id;
             this.currentRequest = request;

             this.integrazioni = '';

             let vm = this;
             setTimeout(function() {
                 vm.$refs['integrazioniModal'].show();
             }, 200);
         },

         hideIntegrazioniModal() {
             let vm = this;
             setTimeout(function() {
                 vm.$refs['integrazioniModal'].hide();
             }, 200);
         },

         confermaIntegrazioniModal() {
             if ((typeof this.currentRequest.sportingclubid !== "undefined") || (typeof this.sportingclub !== "undefined" && this.sportingclub && typeof this.sportingclub.id !== "undefined")) {
                 Swal.fire({
                     title: 'Conferma di mettere la Richiesta in stato di Integrazioni?',
                     text: "Sei sicuro di voler mettere la Richiesta in stato di Integrazioni?",
                     icon: 'warning',
                     showCancelButton: true,
                     showConfirmButton: true,
                     confirmButtonText: 'Conferma',
                     cancelButtonText: 'Annulla',
                 }).then((result) => {
                     if (result.isConfirmed) {
                         this.loading = true;

                         let url = '';

                         let sportingclubid = null;
                         if (typeof this.currentRequest.sportingclubid !== "undefined") sportingclubid = this.currentRequest.sportingclubid;
                         if (typeof this.sportingclub !== "undefined" && this.sportingclub && typeof this.sportingclub.id !== "undefined") sportingclubid = this.sportingclub.id;

                         url = '/requestes/additions-request?idsportingclub=' + sportingclubid;

                         let data = { request_id: this.request_id, integrazioni: this.integrazioni };
                         ApiService.post(url, data)
                                   .then((response) => {
                                       if (response.data.status === 'OK') {
                                           this.$bvToast.toast('Richiesta messa correttamente in stato di integrazioni.', {
                                               title: 'Richiesta messa correttamente in stato di integrazioni.',
                                               variant: 'success',
                                               solid: true
                                           });

                                           this.loading = false;

                                           if (typeof this.$refs.richiesteBozza !== "undefined") this.$refs.richiesteBozza.refresh();
                                           if (typeof this.$refs.richiesteGestione !== "undefined") this.$refs.richiesteGestione.refresh();

                                           this.hideIntegrazioniModal();
                                       }

                                       if (response.data.status === 'KO') {
                                           this.$bvToast.toast('Si è verificato un errore nel mettere la Richiesta in stato di integrazioni.', {
                                               title: 'Errore nel mettere la Richiesta in stato di integrazioni.',
                                               variant: 'danger',
                                               solid: true
                                           });

                                           this.loading = false;
                                       }
                                   })
                                   .catch((error) => {
                                       this.$bvToast.toast('Si è verificato un errore nel mettere la Richiesta in stato di integrazioni.', {
                                           title: 'Errore nel mettere la Richiesta in stato di integrazioni.',
                                           variant: 'danger',
                                           solid: true
                                       });

                                       this.loading = false;
                                   })
                     }
                 })
             }
         },

         acceptRequest(request) {
             this.request_id = request.id;
             this.currentRequest = request;

             this.integrazioni = '';
             if (typeof request.info !== "undefined") this.integrazioni = request.info;

             let vm = this;
             setTimeout(function() {
                 vm.$refs['accettaModal'].hide();
             }, 200);
         },

         hideAccettaModal() {
             let vm = this;
             setTimeout(function() {
                 vm.$refs['accettaModal'].hide();
             }, 200);
         },

         confermaAccettaModal() {
             if ((typeof this.currentRequest.sportingclubid !== "undefined") || (typeof this.sportingclub !== "undefined" && this.sportingclub && typeof this.sportingclub.id !== "undefined")) {
                 Swal.fire({
                     title: 'Conferma di accettare la Richiesta?',
                     text: "Sei sicuro di voler accettare la Richiesta?",
                     icon: 'success',
                     showCancelButton: true,
                     showConfirmButton: true,
                     confirmButtonText: 'Conferma',
                     cancelButtonText: 'Annulla',
                 }).then((result) => {
                     if (result.isConfirmed) {
                         this.loading = true;

                         let url = '';

                         let sportingclubid = null;
                         if (typeof this.currentRequest.sportingclubid !== "undefined") sportingclubid = this.currentRequest.sportingclubid;
                         if (typeof this.sportingclub !== "undefined" && this.sportingclub && typeof this.sportingclub.id !== "undefined") sportingclubid = this.sportingclub.id;

                         url = '/requestes/accept-request?idsportingclub=' + sportingclubid;

                         let data = { request_id: this.request_id, integrazioni: this.integrazioni, filereceipt: this.filereceipt };
                         ApiService.post(url, data)
                                   .then((response) => {
                                       if (response.data.status === 'OK') {
                                           this.$bvToast.toast('Richiesta accettata correttamente.', {
                                               title: 'Richiesta accettata correttamente.',
                                               variant: 'success',
                                               solid: true
                                           });

                                           this.loading = false;

                                           if (typeof this.$refs.richiesteBozza !== "undefined") this.$refs.richiesteBozza.refresh();
                                           if (typeof this.$refs.richiesteGestione !== "undefined") this.$refs.richiesteGestione.refresh();

                                           this.hideAccettaModal();
                                       }

                                       if (response.data.status === 'KO') {
                                           this.$bvToast.toast('Si è verificato un errore nell\'accettazione della Richiesta.', {
                                               title: 'Errore nell\'accettazione della Richiesta.',
                                               variant: 'danger',
                                               solid: true
                                           });

                                           this.loading = false;
                                       }
                                   })
                                   .catch(({ response }) => {
                                       if (response.status === 422) {
                                           this.$bvToast.toast('Si è verificato un errore nell\'accettazione della Richiesta.', {
                                               title: 'Errore nell\'accettazione della Richiesta.',
                                               variant: 'danger',
                                               solid: true
                                           });

                                           if (typeof response.data.fileerrors !== "undefined") {
                                               this.fileErrors = response.data.fileerrors;
                                           }
                                       }
                                       else {
                                           console.log(response);
                                       }

                                       this.loading = false;
                                   })
                     }
                 })
             }
         },

         appendToken(url) {
             if (url) {
                 let parts = url.split('?');
                 if (parts.length >= 2) {
                     return url += '&token=' + JwtService.getToken();
                 }
                 else
                 {
                     return url += '?token=' + JwtService.getToken();
                 }
             }

             return url;
         },

         CreditRecharged() {
             this.$router.go();
         },
     },
 };
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
 .multiselect__option--highlight {
     background-color: #0066CC;
 }

 .multiselect__option--selected {
     background-color: #187DE4;
     color: #FFFFFF;
 }

 .multiselect__option--selected.multiselect__option--highlight {
     background-color: #8950FC;
     color: #FFFFFF;
 }

 .multiselect {
     width: 100px;
     background-color: #E1F0FF;
 }

 .multiselect__content-wrapper {
     background-color: #E1F0FF;
 }

 .multiselect__tags {
     background: transparent;
 }

 .multiselect__input, .multiselect__single {
     background: transparent;
 }

 .multiselect__tag {
     background-color: #0066CC;
 }

 .multiselect {
     border: 1px solid #0066CC !important;
 }

 .multiselect.is-invalid {
     border: 1px solid #F64E60 !important;
     border-radius: 0.42rem;
     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23F64E60' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F64E60' stroke='none'/%3e%3c/svg%3e");
     background-repeat: no-repeat;
     background-position: right calc(0.375em + 0.325rem + 0.75em + 0.65rem) center;
     background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
 }
</style>
